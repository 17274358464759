import React, {useContext} from 'react';
import Answer from './Answer';
import QuizContext from '../context/QuizContext';

function Answers() {
    const {state, dispatch} = useContext(QuizContext);
    const {currentAnswer, currentQuestion, questions} = state;
    const question = questions[currentQuestion];

    return (
        <div className="answers-cotainer">
            <Answer
                letter="yes"
                answer={question.answer_yes}
                dispatch={dispatch}
                selected={currentAnswer === 'yes'}
            />
            <Answer
                letter="no"
                answer={question.answer_no}
                dispatch={dispatch}
                selected={currentAnswer === 'no'}
            />
        </div>
    );
}

export default Answers;
