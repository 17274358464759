import React from 'react';
function CombinationCard(props) {
    return (
        <a target="_blank" rel="noopener noreferrer" href={props.link}>
        <div className="combination-card">
            <div className="combination-icon">
            {props.icon}
            </div>
            <div className="combination-description">
            <h2>{props.product}</h2>
            <h3>{props.title}</h3>
            <h3>Coverage: ${props.coverage}</h3>
            </div>
            <div className="combination-premium"></div>
            <h1>${props.premium}</h1>
        </div>
        </a>
        
    );
}

export default CombinationCard