import React, {useReducer} from 'react';
import Progress from './components/Progress';
import Question from './components/Question';
import Answers from './components/Answers';
import Result from './components/Result';
import QuizContext from './context/QuizContext';

import {
    SET_ANSWERS,
    SET_CURRENT_QUESTION,
    SET_CURRENT_ANSWER,
    SET_ERROR,
    SET_SHOW_RESULTS,
    SET_SHOW_STARTING,
    SET_SCORE,
    SET_HEALTH,
} from './reducers/types.js';
import quizReducer from './reducers/QuizReducer';

import './App.css';

import startingmain from './image/startingmain.jpeg';

import { q_img, questions } from './constant';

function App() {

    const initialState = {
        questions,
        currentQuestion: 0,
        currentAnswer: '',
        answers: [],
        showResults: false,
        showStarting: true,
        error: '',
        score: 50,
        health: 30,
    };

    const [state, dispatch] = useReducer(quizReducer, initialState);
    const {currentQuestion, currentAnswer, answers, showResults, showStarting, error, health, score} = state;

    const question = questions[currentQuestion];

    const renderError = () => {
        if (!error) {
            return;
        }

        return <div className="error">{error}</div>;
    };

    const start = () => {
        dispatch({ type: SET_SHOW_STARTING, showStarting: false});
        return;
    }

    const next = () => {
        const answer = {questionId: question.id, answer: currentAnswer};
        if (!currentAnswer) {
            dispatch({type: SET_ERROR, error: 'Please select an option'});
            return;
        }

        answers.push(answer);
        let changeOfScore = question.risk_ratio[answer.answer]

        dispatch({ type: SET_SCORE, score: score+changeOfScore.score});
        dispatch({ type: SET_HEALTH, health: health+changeOfScore.health});
        dispatch({type: SET_ANSWERS, answers});
        dispatch({type: SET_CURRENT_ANSWER, currentAnswer: ''});
        if (currentQuestion + 1 < questions.length) {
            dispatch({
                type: SET_CURRENT_QUESTION,
                currentQuestion: currentQuestion + 1,
            });
            return;
        }

        dispatch({type: SET_SHOW_RESULTS, showResults: true});
        //renderResultsData();
    };

    if (showResults) {
        return (
            <QuizContext.Provider value={{state, dispatch}}>
                <Result />
            </QuizContext.Provider>
        );
    } else if (showStarting) {
        return (
            <QuizContext.Provider value={{state, dispatch}}>
                <div className="container">
                    <h1>Life of Survival</h1>
                    <img className="starting-img" src={startingmain} alt="starting-main"/>
                    <div className="starting-rule">
                    <h2>Guides</h2>
                    <p>Welcome aborad! This is Stephen. Thank you for spending your valuable time here. <strong>Life of survival</strong> is the life simulation game that you will be asked about various personal decisions. Just a few moments, you will obtain some valuable recommendations about your life!</p>
                    <p>Please try to answer the questions as if you are overcoming some real-life decisions. Please be assured that the data collected will be kept in the strictest confidentiality and only for the evaluation purpose of HSBC Life.</p>
                    <p>Let’s get started!</p>
                    </div>
                    <button className="btn btn-primary" onClick={start}>
                        Let's Start
                    </button>
                </div>
            </QuizContext.Provider>
        );
    } else {
        return (
            <QuizContext.Provider value={{state, dispatch}}>
                <div className="container">
                    <Progress
                        total={questions.length}
                        current={currentQuestion + 1}
                        score={score}
                        health={health}
                    />
                    <Question />
                    {renderError()}
                    <img className="quiz-img" src={q_img[currentQuestion]} alt="quiz" />
                    <Answers />
                    <button className="btn btn-primary" onClick={next}>
                        Confirm and Continue
                    </button>
                </div>
            </QuizContext.Provider>
        );
    }
}

export default App;
