import {
    SET_ANSWERS,
    SET_CURRENT_QUESTION,
    SET_CURRENT_ANSWER,
    SET_ERROR,
    SET_SHOW_RESULTS,
    SET_SHOW_STARTING,
    RESET_QUIZ,
    SET_SCORE,
    SET_HEALTH
} from './types.js';

function quizReducer(state, action) {
    switch (action.type) {
        case SET_CURRENT_ANSWER:
            return {
                ...state,
                currentAnswer: action.currentAnswer,
            };
        case SET_CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.currentQuestion,
            };
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case SET_SHOW_RESULTS:
            return {
                ...state,
                showResults: action.showResults,
            };
        case SET_SHOW_STARTING:
            return {
                ...state,
                showStarting: action.showStarting,
            };
        case SET_ANSWERS:
            return {
                ...state,
                answers: action.answers,
            };
        case SET_SCORE:
            return {
                ...state,
                score: action.score,
            }
        case SET_HEALTH:
            return {
                ...state,
                health: action.health,
            }
        case RESET_QUIZ:
            return {
                ...state,
                answers: [],
                currentQuestion: 0,
                currentAnswer: '',
                showResults: false,
                error: '',
                score: 50,
                health: 30,
            };
        default:
            return state;
    }
}

export default quizReducer;
