import React, {useContext} from 'react';
import QuizContext from '../context/QuizContext';
import {RESET_QUIZ} from '../reducers/types';
import ResultCard from './ResultCard';
import CombinationCard from './CombinationCard';
import {
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer
  } from 'recharts';
import {getTwoProduct} from "../constant";
function Result() {
    const product =  getTwoProduct();
    const fpprice = Math.round(100 + Math.random()*100,1);
    const wgip = Math.round(100 + Math.random()*100,1);
    const {dispatch} = useContext(QuizContext);
    const restart = () => {
        dispatch({type: RESET_QUIZ});
    };
    const renderCombination = () => {
      if ( product.length === 2) {
        return (
          <div className="CombinationCardContainer">
                          
            <CombinationCard
            icon={product[0].icon}
            product={`${product[0].product}`} 
            title={`${product[0].title}`}
            coverage={`${product[0].coverage}`}
            premium={fpprice}
            link={`${product[0].link}`}
            />
            <CombinationCard
            icon={product[1].icon} 
            product={`${product[1].product}`}  
            title={`${product[1].title}`}
            coverage={`${product[1].coverage}`}
            premium={wgip}
            link={`${product[1].link}`}
            />
          </div>
        );
      }
      return ;
    }
    const data = [
        {
          subject: 'Risk tolerance', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
        {
          subject: 'Risk taking', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
        {
          subject: 'Problem solving', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
        {
          subject: 'Health awareness', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
        {
          subject: 'Logical Reasoning', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
        {
          subject: 'Saving ability', A: Math.floor(Math.random() * 100), fullMark: 100,
        },
    ];
    return (
        <div className="container results">
                <h2>Results</h2>
                <ResponsiveContainer width="85%" height={200}>
                <RadarChart data={data}>
                    <PolarGrid />
                    <PolarAngleAxis tick={{fontSize: 10, transform: 'translate(0, 5)',fill: '#ffffff' }} dataKey="subject" />
                    <PolarRadiusAxis tick={{fontSize: 10}}/>
                    <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                </RadarChart>
                </ResponsiveContainer>
                <div className="ResultCardContainer">
                    <ResultCard title="Overall Risk Level" value={Math.round(50+Math.random()*50,2)+`/100`}/>
                    <ResultCard title="Total Monthly Premium" value={`$`+(fpprice+wgip)}/>
                    <ResultCard title="Total Yearly Premium" value={`$`+(fpprice+wgip)*12}/>
                </div>
                <div className="DiscountContainer">
                    <h3>EXTRA OFFER! 10% Discount Code: <br/> Expired in 7 days</h3>
                    <h2>10DISC</h2>
                </div>
                <div className="CombinationContainer">
                    <div className="CombinationTitle">
                        <h2>Your Combination</h2>
                        <h3> Monthly Premium</h3>
                    </div>
                    {renderCombination()}
                </div>
                <button className="btn btn-primary" onClick={restart}>
                    Restart
                </button>
        </div>
    );
}

export default Result;