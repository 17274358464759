import React from 'react';
function ResultCard(props) {
    return (
        <div className="result-card">
            <h4>{props.title}</h4>
            <div className="result-cardInner">
            <h3>{props.value}</h3>
            </div>
        </div>
        
    );
}

export default ResultCard