import React from 'react';
import q1 from './image/q1.png';
import q2 from './image/q2.png';
import q3 from './image/q3.png';
import q4 from './image/q4.png';
import q5 from './image/q5.png';
import q6 from './image/q6.png';
import q7 from './image/q7.png';
import q8 from './image/q8.png';

import { RiHomeHeartLine, RiMoneyDollarCircleLine , RiAwardLine} from "react-icons/ri";
import { GiFamilyHouse } from "react-icons/gi"

export const questions = [
    {
        id: 1,
        question: 'Do you want fish ball?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-5, health:-3},no:{score:0, health:5}}
    },
    {
        id: 2,
        question: 'Do you want ice cream for dessert?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-5, health:-5},no:{score:0, health:5}}
    },
    {
        id: 3,
        question: 'Are you drinking enough water today (3 L as optimal)?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-1, health:5},no:{score:0, health:-5}}
    },
    {
        id: 4,
        question: 'Have you eaten any vegetables or fruits today?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-3, health:5},no:{score:0, health:-5}}
    },
    {
        id: 5,
        question: 'Will you spend some time doing sports today? (e.g. hiking/ swimming/ running)',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-1, health:5},no:{score:0, health:-5}}
    },
    {
        id: 6,
        question: 'Do you want alcohol or soft drinks today?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-3, health:-3},no:{score:0, health:+3}}
    },
    {
        id: 7,
        question: 'Do you smoke?',
        answer_yes:'Yes',
        answer_no: 'No',
        risk_ratio: {yes:{score:-5, health:-5},no:{score:0, health:+5}}
    },
    {
        id: 7,
        question: 'Do you prefer cooking for yourself or eating out/ takeaway for today’s lunch?',
        answer_yes:'Cooking for yourself',
        answer_no: 'Eating out/takeaway',
        risk_ratio: {yes:{score:-2, health:2},no:{score:-5, health:-2}}
    },

];
export const q_img = [q1, q2, q3, q4, q5, q6, q7, q8];

export const productArray = [
    {
        icon: <GiFamilyHouse/>,
        product: 'HSBC Family Protector',
        title: 'With critical illness and cancer protection',
        coverage: '1000,000',
        link: "https://www.hsbc.com.hk/insurance/products/life/family-protector/"
    },
    {
        icon: <RiMoneyDollarCircleLine/>,
        product: 'HSBC Wealth Goal Insurance Plan II',
        title: 'Your wealth can be built gradually and support your long-term savings goal',
        coverage: '2000,000',
        link: "https://www.hsbc.com.hk/insurance/products/life/wealth-goal/"
    },
    {
        icon: <RiAwardLine/>,
        product: 'Goal Access Universal Life Plan',
        title: 'Flexible savings and life insurance',
        coverage: '1000,000',
        link: "https://www.hsbc.com.hk/insurance/products/life/goal-access-universal/"
    },
    {
        icon: <RiHomeHeartLine/>,
        product: 'Mortgage Protection Plan',
        title: 'Ensure your family always has a home',
        coverage: '1000,000',
        link: "https://www.hsbc.com.hk/insurance/products/life/mortgage-protection/"
    },
]

function getRandomColor(){
    var num = Math.floor(Math.random() * productArray.length); // get a random number between 0-9
    return productArray[num];
}

export const getTwoProduct = () => {
    const res = [];
    while(res.length<2){
        var tmpproduct = getRandomColor()
        if (!(res.includes(tmpproduct))){
            res.push(tmpproduct)
        }
    }
    return res
}